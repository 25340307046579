import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/minimal-layout';
export const _frontmatter = {
  "title": "Confirmed your newsletter subscription",
  "skipNewsletter": true
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Confirmed! 🎉`}</h1>
    <p>{`Thanks for subscribing to the Blocks newsletter. In the near future you'll be receiving emails from us.`}</p>
    <br />
    <p><em parentName="p">{`Thanks,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Blocks team`}</em></p>
    <br />
    <p><a parentName="p" {...{
        "href": "/"
      }}>{`Back to the homepage `}{`→`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      